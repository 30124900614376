import React from "react";
import Template from '../components/Template/Template';
import Faq from '../sections/Faq/Faq';

const Marki = () => {

    return (
        <Template path="/marka-samsung">
           
           <h1 className="ch1">Samsung - skup tonerów</h1>
            <section id="pSell" className="ccon">
                <p>Drukarki laserowe firmy Samsung pozwalają na przesyłanie materiałów przez Wifi, pobierają także dokumenty z chmury. Ale co z tego, jeśli wykorzystujemy ich możliwości tak rzadko, że nawet nie musimy otwierać zapasowych tonerów?</p>
                <p>Jeśli posiadają Państwo niepotrzebne, pełne wkłady do drukarek, zgłoście się do nas. Skupujemy oryginalne tonery Samsung, zarówno od klientów indywidualnych, jak i biznesowych. Przyjmujemy pojedyncze egzemplarze, a także całe zapasy. W Warszawie sami przyjedziemy po produkty, a do innych miast Polski poślemy kuriera.</p>
                <p>Skup tonerów daje Państwu szansę na odzyskanie części zainwestowanych pieniędzy. Pozwala także na wspieranie środowiska naturalnego &ndash; materiały, z których wykonane są wkłady, mogą zostać poddane recyklingowi!</p>
            </section>
    

        </Template>
    )
}

export default Marki
